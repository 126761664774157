// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZ_DVOC_ejT_kWLD0relDLtQ-2UFTnLTo",
  authDomain: "landing-page-378d0.firebaseapp.com",
  projectId: "landing-page-378d0",
  storageBucket: "landing-page-378d0.appspot.com",
  messagingSenderId: "111075168928",
  appId: "1:111075168928:web:038dfe1b7e659951612fc5",
  measurementId: "G-3B9BBCG66R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };