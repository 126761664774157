import React from 'react';
import { db } from './firebase-config';
import { collection, addDoc } from "firebase/firestore"; 
import './App.css';
import { FaBrain, FaDollarSign, FaRobot } from 'react-icons/fa'; // Importing specific icons
import { FaGears } from "react-icons/fa6";
import { useState } from 'react';

function SignupForm() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "users"), {
        email: email,
      });
      console.log("Document written with ID: ", docRef.id);
      alert("Thanks for signing up!");
      setEmail('');  // Clear input after submission
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className="signup-form-container">
      <h2>Get Early Access!</h2>
      <form onSubmit={handleSubmit} className="signup-form">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
}

function DualPanel() {
  return (
    <div className="dual-panel">
      <div className="panel panel-left">
        <h2>Our Mission</h2>
        <p className='mission'>Revolutionizing financial services with advanced AI and ML to automate routine tasks, enhance decision-making, and drive efficiency. Our platform empowers firms to optimize operations, save time, and focus on strategic goals for superior outcomes.</p>
      </div>
      <div className="divider"></div>
      <div className="panel panel-right">
        <h2>Key Features</h2>
        <ul>
          <li>< FaGears size="2em"/> Automate and simplify your financial modeling with our seamless, efficient tools.</li>
          <li><FaBrain size="2em"/> Leverage advanced ML for accurate forecasts and smarter valuation.</li>
          <li><FaRobot size="2em"/> Quickly get assistance and adjust financial models with our intuitive, real-time chatbot. </li>
        </ul>
      </div>
    </div>
  );
}

function Navbar() {
  return (
    <nav className="navbar">
      <h2 className="logo">Valued AI</h2>
    </nav>
  );
}

function Banner() {
  return (
    <div className="banner">
      <h1>Streamline, predict, and excel</h1>
      <p>Valued AI is your AI-powered toolkit for next-generation financial modeling and analysis.</p>
    </div>
  );
}


function App() {
  const [email, setEmail] = React.useState('');

  return (
    <div className="App">
      <Navbar />
      <Banner />
      <DualPanel />
      <SignupForm />
      {/* <header>
        <h1>Welcome to Valued AI</h1>
        <p>Your end-to-end platform for efficient financial modeling and analysis.</p>
      </header>
      <section>
        <h2>Automate Your Financial Tasks</h2>
        <p>Use our AI and ML-driven tools to speed up financial modeling, automate data fetching, and perform predictive analysis.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit">Sign Up</button>
        </form>
      </section> */}
    </div>
  );
}

export default App;

